<template>
  <main class="bg-gray-600">
    <section class="section is-medium">
      <div class="container">
        <div class="bg-white shadow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t('about_title') }}
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              {{ $t('about_subtitle') }}
            </p>
          </div>
          <div class="px-4 py-5 sm:p-0">
            <dl>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dd class="text-sm leading-5 font-medium text-gray-500">
                  {{ $t('about_version') }}
                </dd>
                <dt
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {{ version }}
                </dt>
              </div>
              <div
                class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
              >
                <dd class="text-sm leading-5 font-medium text-gray-500">
                  {{ $t('about_build_date') }}
                </dd>
                <dt
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {{ buildDate | datetime }}
                </dt>
              </div>
              <div
                class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
              >
                <dd class="text-sm leading-5 font-medium text-gray-500">
                  {{ $t('about_support') }}
                </dd>
                <dt
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <a :href="mailto">support@wotime.ch</a>
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import datetime from '@/filters/datetime';
export default {
  name: 'About',
  filters: {
    datetime,
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION ?? 'N/A';
    },
    buildDate() {
      return process.env.VUE_APP_BUILD_DATE ?? 'N/A';
    },
    mailto() {
      return `mailto:support@wotime.ch?subject=Support version=${this.version} / ${this.buildDate}`;
    },
  },
};
</script>
